var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-card-subtitle", [
                _vm._v(
                  "\n        Select target file matching options and upload file.\n      "
                )
              ]),
              _c("div", { staticClass: "mb-7 text-center" }, [
                _vm._v("\n        Target File:\n        "),
                _c(
                  "div",
                  [
                    _c("v-chip", { attrs: { color: "success" } }, [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.$route.query.sc))
                      ])
                    ])
                  ],
                  1
                )
              ]),
              _c(
                "v-row",
                { staticClass: "mb-5", attrs: { justify: "space-between" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "ml-3" },
                        [
                          _c("v-card-subtitle", [_vm._v(" File Type: ")]),
                          _c(
                            "v-list-item-group",
                            {
                              attrs: { mandatory: "", multiple: "" },
                              model: {
                                value: _vm.selectedFileType,
                                callback: function($$v) {
                                  _vm.selectedFileType = $$v
                                },
                                expression: "selectedFileType"
                              }
                            },
                            _vm._l(_vm.fileTypes, function(item, i) {
                              return _c("v-list-item", {
                                key: i,
                                attrs: { value: item },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var active = ref.active
                                        return [
                                          _c(
                                            "v-list-item-action",
                                            [
                                              _c("v-checkbox", {
                                                attrs: { "input-value": active }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.value
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "mr-3" },
                        [
                          _c("v-card-subtitle", [
                            _vm._v(" Target File Match Options: ")
                          ]),
                          _c(
                            "v-list-item-group",
                            {
                              attrs: { mandatory: "", multiple: "" },
                              model: {
                                value: _vm.selectedMatch,
                                callback: function($$v) {
                                  _vm.selectedMatch = $$v
                                },
                                expression: "selectedMatch"
                              }
                            },
                            _vm._l(_vm.matchTypes, function(item, i) {
                              return _c("v-list-item", {
                                key: i,
                                attrs: { value: item },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var active = ref.active
                                        return [
                                          _c(
                                            "v-list-item-action",
                                            [
                                              _c("v-checkbox", {
                                                attrs: { "input-value": active }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.description
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-card-title", [_vm._v(" Upload File ")]),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-file-input", {
                        attrs: {
                          dense: "",
                          clearable: "",
                          "show-size": "",
                          accept: ".csv",
                          rules: _vm.targetFileRules,
                          label: "Choose File"
                        },
                        on: {
                          change: function($event) {
                            return _vm.clearErrors()
                          }
                        },
                        model: {
                          value: _vm.targetDataFile,
                          callback: function($$v) {
                            _vm.targetDataFile = $$v
                          },
                          expression: "targetDataFile"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-col", [
                    _vm.uploadError !== ""
                      ? _c(
                          "small",
                          {
                            staticClass: "ml-10 mb-5",
                            staticStyle: { color: "red" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.uploadError) +
                                "\n          "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ],
                1
              ),
              _c("div", { staticClass: "font-italic pl-7 inline-block" }, [
                _vm._v("\n        Valid file extensions: .csv\n      ")
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "success",
                        loading: _vm.uploadingFiles,
                        disabled:
                          _vm.targetDataFile === null || !_vm.isValidTypeFile
                      },
                      on: {
                        click: function($event) {
                          return _vm.validateUpload()
                        }
                      }
                    },
                    [_vm._v("\n          Upload file\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "tertiary" },
                      on: {
                        click: function($event) {
                          return _vm.cancel()
                        }
                      }
                    },
                    [_vm._v("\n          cancel\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }