<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card-subtitle>
          Select target file matching options and upload file.
        </v-card-subtitle>
        <div
          class="mb-7 text-center"
        >
          Target File:
          <div>
            <v-chip color="success">
              <span class="font-weight-bold">{{ $route.query.sc }}</span>
            </v-chip>
          </div>
        </div>
        <v-row
          justify="space-between"
          class="mb-5"
        >
          <v-col cols="6">
            <v-card class="ml-3">
              <v-card-subtitle> File Type: </v-card-subtitle>
              <v-list-item-group
                v-model="selectedFileType"
                mandatory
                multiple
              >
                <v-list-item
                  v-for="(item, i) in fileTypes"
                  :key="i"
                  :value="item"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                      />
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.value" />
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card class="mr-3">
              <v-card-subtitle> Target File Match Options: </v-card-subtitle>
              <v-list-item-group
                v-model="selectedMatch"
                mandatory
                multiple
              >
                <v-list-item
                  v-for="(item, i) in matchTypes"
                  :key="i"
                  :value="item"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                      />
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.description" />
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider />
    <v-row>
      <v-col>
        <v-card-title> Upload File </v-card-title>
        <v-row>
          <v-col cols="4">
            <v-file-input
              v-model="targetDataFile"
              dense
              clearable
              show-size
              accept=".csv"
              :rules="targetFileRules"
              label="Choose File"
              @change="clearErrors()"
            />
          </v-col>
          <v-col>
            <small
              v-if="uploadError !== ''"
              class="ml-10 mb-5"
              style="color: red"
            >
              {{ uploadError }}
            </small>
          </v-col>
        </v-row>
        <div class="font-italic pl-7 inline-block">
          Valid file extensions: .csv
        </div>
        <v-card-actions>
          <v-btn
            color="success"
            :loading="uploadingFiles"
            :disabled="targetDataFile === null || !isValidTypeFile"
            @click="validateUpload()"
          >
            Upload file
          </v-btn>
          <v-btn
            color="tertiary"
            @click="cancel()"
          >
            cancel
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { azBlobService, nowwService } from '@/shared/services'
import { mapGetters } from 'vuex'
import mutationTypes from '@/store/mutation-types'

export default {
  name: 'TargetDataFileUpload',

  CampaignDashboardItem: () => import('@/views/pages/components/mailing/CampaignDashboardItem'),

  props: {
    suppressionCode: {
      type: String,
      required: true
    },
    stepVal: {
      type: Number,
      default: 2
    }
  },

  data () {
    return {
      selectedFileType: [],
      selectedMatch: [],
      results: [],
      uploadResults: [],
      valid: false,
      brandName: '',
      isSingleColumn: false,
      targetDataFile: null,
      fileData: '',
      row: '',
      row0: '',
      fileArray: [],
      targetFileRules: [
        value =>
          !value ||
            value.size < 11000000 ||
            `${vm.contactType} Contact size should be less than 11 MB`
      ],
      resultsLoading: false,
      uploadingFiles: false,
      uploadError: '',
      isValidTypeFile: true,
      fileTypes: [
        { value: 'AMA', description: 'AMA' },
        { value: 'APN', description: 'APN' },
        { value: 'AAPA', description: 'AAPA' }
      ],
      matchTypes: [
        { value: 'ME', description: 'ME Numbers:10 or 11-digit (AMA)' },
        { value: 'MIX', description: 'MMS ID Numbers (AMA)' },
        { value: 'Name', description: 'Name/Zip Match' },
        { value: 'NPI', description: 'NPI Number' }
      ],
      amaSelected: 0,
      apnSelected: 0,
      aapaSelected: 0,
      meSelected: 0,
      match11: 0,
      mixSelected: 0,
      nameSelected: 0,
      npiSelected: 0
    }
  },

  computed: {
    ...mapGetters(['viewAsCustomer', 'simulatedCustomerCrmId']),
    ...mapGetters('auth', [
      'isMMSRole',
      'getTenantId',
      'getTenantCrmId'
    ]),
    showViewAsCustomer () {
      return this.$store.getters['auth/isMMSRole']()
    },
    isLoading () {
      return this.loadingCount > 0
    }
  },

  watch: {
    stepCounter (newValue, oldValue) {
      if (this.results.length !== 0 & newValue !== undefined) {
        this.getDrLookup()
      }
    }
  },

  mounted () {
    this.unsubscribe = this.$store.subscribe(mutation => {
      let listeners = [
        mutationTypes.SET_VIEW_AS_CUSTOMER
      ]
      if (listeners.includes(mutation.type)) {
        this.loadTargetData()
      }
    })
  },

  created () {
  },

  methods: {
    clearErrors () {
      if (this.targetDataFile !== null && !this.targetDataFile.type.match('text/csv')) {
        this.uploadError = 'This is not a valid CSV file'
        this.isValidTypeFile = false
      } else {
        this.uploadError = ''
        this.isValidTypeFile = true
      }
    },
    cancel () {
      this.$router.push({ path: '/targetData' })
    },
    async validateUpload () {
      var _this = this
      this.uploadError = ''
      for (var i = 0; i < this.selectedFileType.length; i++) {
        if (this.selectedFileType[i].value === 'AMA') {
          this.amaSelected = 1
        } else if (this.selectedFileType[i].value === 'APN') {
          this.apnSelected = 1
        } else if (this.selectedFileType[i].value === 'AAPA') {
          this.aapaSelected = 1
        }
      }
      for (var j = 0; j < this.selectedMatch.length; j++) {
        if (this.selectedMatch[j].value === 'ME') {
          this.meSelected = 1
        }
        if (this.selectedMatch[j].value === 'MIX') {
          this.mixSelected = 1
        }
        if (this.selectedMatch[j].value === 'Name') {
          this.nameSelected = 1
        }
        if (this.selectedMatch[j].value === 'NPI') {
          this.npiSelected = 1
        }
        if ((this.amaSelected !== 1) && (this.selectedMatch[j].value === 'ME' || this.selectedMatch[j].value === 'MIX')) {
          this.uploadError = 'You cannot select an ME Number Match Option when the AMA file has not been selected.'
        }
      }

      var reader = new FileReader()
      reader.readAsText(this.targetDataFile)
      reader.onload = async () => {
        _this.fileData = reader.result
        var newLine = '\r\n'
        _this.fileArray = _this.fileData.split(newLine)
        _this.row0 = _this.fileArray[0].toString()
        if (_this.uploadError === '') {
          _this.uploadingFiles = true
          _this.uploadFile()
        }
      }
    },
    async uploadFile () {
      var _this = this
      _this.resultsLoading = true
      var customerId = _this.$store.getters['simulatedCustomerNowwId']()
      var formData = new FormData()
      var customerNowwId = this.$store.getters['simulatedCustomerNowwId']()
      var targetdataid = _this.$route.query.sid
      formData.append('nowwId', customerNowwId)
      formData.append('targetdataid', targetdataid)
      formData.append('files[]', _this.targetDataFile)
      await azBlobService.uploadTd(formData)
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.uploadResults = resp
          }
        })
      await nowwService.importUpdateMatch({
        customerId: customerId,
        supressionCode: _this.$route.query.sc,
        amaSelect: _this.amaSelected,
        apnSelect: _this.apnSelected,
        aapaSelect: _this.aapaSelected,
        match10: _this.meSelected,
        match11: _this.match11,
        matchMixed: _this.mixSelected,
        matchName: _this.nameSelected,
        matchNPI: _this.npiSelected
      })
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.results = resp
            _this.resultsLoading = false
            _this.$emit('advanceFromStep', _this.stepVal)
            _this.$emit('amaSelected', _this.amaSelected)
            _this.$emit('selectedMatch', _this.selectedMatch)
            _this.$emit('row0', _this.row0)
            _this.$emit('fileArray', _this.fileArray)
            _this.$emit('fileData', _this.fileData)
          }
        })
        .finally(() => {
          _this.uploadingFiles = false
        })
    }
  }

}
</script>
